:root {
  --stroke-value: 0;
}

.stat-container {
  position: absolute;
}

.circle {
  stroke-dasharray: 227;
  stroke-dashoffset: 227;
  animation-name: rotate;
  animation-delay: 300ms;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}


@keyframes rotate {
  to {
    stroke-dashoffset: var(--stroke-value);
  }
}