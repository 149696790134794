body {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@media (max-width: 599.95px) {
  #root {
    width: 100%;
  }
}

.hide {
  display: none !important;
}
