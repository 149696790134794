.fade-appear {
  opacity: 0;
  transform: translateY(5%);
  
  &.fade-appear-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 100ms ease-in-out;
  }
}

.fade-enter {
  opacity: 0;
  transform: translateY(5%);
  
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 100ms ease-in-out;
  }
}

.fade-enter-done {
  overflow-y: auto;
  overflow-x: hidden;
}

.fade-exit {
  opacity: 1;
  
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }
}